import React from 'react';

import { Box, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';

const DELTA = 0; // offset of min/max values
const gaugeType = {
  power: {
    min: 7,
    max: 36,
  },
  noise: {
    min: 700,
    max: 1300,
  },
  flow: {
    min: 91,
    max: 109,
  },
};

const SensorGauge = ({ className, value, level = 'Good', type = 'power', defaultValue = 50 }) => {
  const minLimit = Math.ceil(Math.min(value, gaugeType[type].min) - DELTA);
  const maxLimit = Math.ceil(Math.max(value, gaugeType[type].max) + DELTA);

  return (
    <StyledRootBox className={className}>
      <Box className="description_box center"></Box>
      <Slider
        disabled
        min={minLimit}
        max={maxLimit}
        track={false}
        defaultValue={defaultValue}
        value={value || defaultValue}
        className="slider"
        valueLabelDisplay="on"
        valueLabelFormat={value => `${level} - ${value}`}
        sx={{
          '& .MuiSlider-valueLabel': {
            border: `1px solid ${level === 'Good' ? '#66BB6A ' : '#FF7A7A'} `,
          },
        }}
      />
    </StyledRootBox>
  );
};

const StyledRootBox = styled(Box)(() => ({
  width: '100%',

  '.description_box': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '&.center': {
      justifyContent: 'center',
    },
  },

  '.slider': {
    padding: '6px 0px',

    '& .MuiSlider-rail': {
      opacity: 1,
      background: `linear-gradient(to right, #FF7A7A 0% 33%, transparent 33% 34%, #66BB6A 34% 65%, transparent 65% 66%, #FF7A7A 66% 100%)`,
    },

    '& .MuiSlider-thumb': {
      width: '4px',
      height: '15px',
      color: '#FFFFFF',
      borderRadius: 0,

      boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
      },
      '&:before': {
        boxShadow:
          '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
      },
    },

    '& .MuiSlider-valueLabel': {
      fontSize: 12,
      fontWeight: 'normal',
      top: -6,
      backgroundColor: 'unset',
      padding: '0px 5px',
      '&::before': {
        display: 'none',
      },
      '& *': {
        background: 'transparent',
      },
    },
  },
}));

export default SensorGauge;
