import React from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Typography, styled } from '@mui/material';

const BeforeBegin = ({ goToStep, resetState }) => {
  return (
    <StyledRootBox>
      <Typography className="title">Before you begin:</Typography>

      <Box className="main_box">
        <Box className="title_wrapper">
          <Typography className="number">1</Typography>

          <Typography className="subtitle">
            <b>Prepare the Scale</b> - Place the scale on a stable, flat surface to ensure accurate
            measurement.
          </Typography>
        </Box>
        <Box className="title_wrapper">
          <Typography className="number">2</Typography>
          <Typography className="subtitle">
            <b>Prepare the Glass</b> - Place the clean, dry drinking glass on the center of the
            scale.
          </Typography>
        </Box>
        <Box className="title_wrapper">
          <Typography className="number">3</Typography>
          <Typography className="subtitle">
            <b>Tare the scale</b> - Press the tare button to ensure the glass is not included in the
            measurement.
          </Typography>
        </Box>
      </Box>
      <Box className="subtitle_box">
        <Typography className="subtitle">
          Once you have completed these steps, press ‘Begin’ to proceed with the calibration.
        </Typography>
      </Box>
      <Box className="action_box">
        <Button
          variant={'contained'}
          onClick={() => {
            resetState();
            goToStep('Detecting pour');
          }}
          color="primary"
        >
          Begin
        </Button>
      </Box>
    </StyledRootBox>
  );
};

export default observer(BeforeBegin);

const StyledRootBox = styled(Box)(({ theme: { palette } }) => ({
  width: '100%',

  '.title': {
    fontSize: '20px',
    fontWeight: '500',
    textAlign: 'left',
    color: palette.whiteEmphasis.medium,
    margin: 0,
    justifyContent: 'left',
  },

  '.main_box': {
    margin: '12px 0',
    gap: '16px',
    border: `1px solid #313131`,
    backgroundColor: '#252525',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',

    flexDirection: 'column',
    padding: '24px',
    justifyContent: 'space-between',

    '.title_wrapper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '.number': {
        color: palette.primary.main,
        backgroundColor: '#2C1F13',
        height: '24px',
        width: '24px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        marginRight: '8px',
      },
      '.title': {
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'left',

        color: palette.whiteEmphasis.high,
      },
      '.subtitle': {
        fontSize: '12px',
        color: palette.whiteEmphasis.high,
        textAlign: 'left',
      },
    },
  },

  '.subtitle_box': {
    margin: '24px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '.subtitle': {
      fontSize: '12px',
      color: palette.whiteEmphasis.high,
    },
  },

  '.action_box': {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    left: 0,
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'center',
    borderTop: `1px solid #313131`,

    button: {
      textTransform: 'none',
      width: 'calc(100% - 40px)',

      height: '40px',

      '&.btn': {
        backgroundColor: '#262626',
        color: palette.whiteEmphasis.high,
      },
    },
  },
}));
