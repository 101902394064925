import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { format } from 'date-fns';

import { useStore } from 'hooks';

export const flowVolumeStatusMap = {
  NOT_CALIBRATED: 0,
  CALIBRATED_WITH_ISSUES: 1,
  CALIBRATED: 2,
  LEGACY: 3,
  //
  0: 'NOT_CALIBRATED',
  1: 'CALIBRATED_WITH_ISSUES',
  2: 'CALIBRATED',
  3: 'LEGACY',
};
const defaultValue = {
  level: 'N/A',
  value: null,
  percentage: 0,
};

const calcPowerLevel = value => (value < 18 ? 'Low' : value > 26 ? 'High' : 'Good');
const calcPowerPercentage = value => {
  if (value < 18) {
    return (value / 18) * 100;
  }
  if (value > 26) {
    const numerator = value - 27;
    const denominator = 37;
    return (numerator / denominator) * 100;
  }
  const numerator = value - 18;
  const denominator = 8;
  return (numerator / denominator) * 100;
};

const calcNoiseLevel = value => (value < 900 ? 'Low' : value > 1100 ? 'High' : 'Good');
const calcNoisePercentage = value => {
  if (value < 900) {
    return (value / 1000) * 100;
  }
  if (value > 1100) {
    const numerator = value - 1101;
    const denominator = 1099;
    return (numerator / denominator) * 100;
  }

  const numerator = value - 900;
  const denominator = 200;
  return (numerator / denominator) * 100;
};

export const calcPower = value => ({
  level: calcPowerLevel(value),
  value: value,
  percentage: Math.round(calcPowerPercentage(value)),
});

export const calcNoise = value => ({
  level: calcNoiseLevel(value),
  value: value,
  percentage: Math.round(calcNoisePercentage(value)),
});

export const calcAccuracy = percentage => ({
  level: percentage > 97 && percentage < 103 ? 'Good' : 'Low',
  percentage: Math.round(percentage),
});

export const useCalibration = sensor => {
  const { topologyManagementStore } = useStore();

  const [currentStep, setCurrentStep] = useState('Initial');
  const [isSensorCalibrated, setIsSensorCalibrated] = useState(!!sensor.calibration_details);
  const [isFlowVolumeCalibrated, setIsFlowVolumeCalibrated] = useState(false);
  const [flowVolumeCalibrationAttempt, setFlowVolumeCalibrationAttempt] = useState(0);
  const [power, setPower] = useState(defaultValue);
  const [noise, setNoise] = useState(defaultValue);
  const [accuracy, setAccuracy] = useState(defaultValue);

  const handleAccuracy = value => {
    if (!topologyManagementStore.pours[0]?.sensor_total_ml || !value) setAccuracy(defaultValue);
    else {
      const percentage = Math.round(
        (value / Math.round(topologyManagementStore.pours[0]?.sensor_total_ml)) * 100,
      );

      setAccuracy({
        level: percentage > 97 && percentage < 103 ? 'Good' : 'Low',
        value: topologyManagementStore.pours[0]?.sensor_total_ml,
        percentage: percentage,
      });
    }
  };

  const updatePower = useCallback(value => {
    setPower(calcPower(value));
  }, []);

  const updateNoise = useCallback(value => {
    setNoise(calcNoise(value));
  }, []);

  const updateAccuracy = useCallback(percentage => {
    setAccuracy(prev => ({
      ...prev,
      ...calcAccuracy(percentage),
    }));
  }, []);

  const updateState = useCallback(
    data => {
      if (data?.power) updatePower(data?.power);
      if (data?.signal) updateNoise(data?.signal);
      if (data?.accuracy_ratio) {
        updateAccuracy(data?.accuracy_ratio * 100);
        setIsFlowVolumeCalibrated(true);
      }
    },
    [updatePower, updateNoise, updateAccuracy],
  );

  useEffect(() => {
    if (![1, 2].includes(sensor?.calibration_status_code)) return;

    updateState(sensor?.calibration_details);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensor?.calibration_status_code]);

  const onSuccessfulCalibration = useCallback(
    calibrationValue => {
      updateState(calibrationValue);
      topologyManagementStore.updateSensorCalibrationInfo(sensor.id, calibrationValue);
      setCurrentStep('Initial');
      topologyManagementStore.resetRecentPours();
    },
    [sensor.id, topologyManagementStore, updateState],
  );

  const latestCalibrationInfo = useMemo(() => {
    if (![1, 2].includes(sensor.calibration_status_code)) return;

    const date = format(new Date(sensor.latest_calibration_at), 'MM/dd/yyyy');
    const time = format(new Date(sensor.latest_calibration_at), 'h:mm a');

    return `Previously calibrated by ${
      sensor._users_display_name_latest_calibration_by || 'N/A'
    } on ${date} at ${time}`;
  }, [
    sensor.latest_calibration_at,
    sensor._users_display_name_latest_calibration_by,
    sensor.calibration_status_code,
  ]);

  return {
    isSensorCalibrated,
    isFlowVolumeCalibrated,
    flowVolumeCalibrationAttempt,
    power,
    noise,
    accuracy,
    currentStep,
    latestCalibrationInfo,
    //
    setIsSensorCalibrated,
    setIsFlowVolumeCalibrated,
    setFlowVolumeCalibrationAttempt,
    setAccuracy,
    handleAccuracy,
    setCurrentStep,
    onSuccessfulCalibration,
  };
};

export const CalibrationContext = React.createContext();
