import { types, flow } from 'mobx-state-tree';

import api from 'services/API';
import { Cooler } from 'models/types';
import { getRootStore } from 'models/root';
import { groupBy } from 'lodash';

export const coolersInitialState = {
  isLoaded: false,
  all: [],
  state: 'done',
  updated: null,
};

const coolerModelWithView = Cooler.views(self => ({
  get bars() {
    const root = getRootStore();

    return self._bar_ids.map(id => {
      return root.barsStore.getBarById(id);
    });
  },

  get barsKeyed() {
    const root = getRootStore();

    return self._bar_ids.map(id => {
      return groupBy(root.barsStore.getBarById(id), 'id');
    });
  },
}));

export const coolersModel = types
  .model({
    isLoaded: types.boolean,
    all: types.array(coolerModelWithView),
    state: types.enumeration('state', ['done', 'pending', 'error']),
    updated: types.maybeNull(types.Date),
    selectedCooler: types.maybeNull(types.reference(coolerModelWithView)),
  })
  .views(self => ({
    get list() {
      return self.all.slice().sort((a, b) => a.name.localeCompare(b.name));
    },

    get currentCooler() {
      // temporary solution. Only one cooler available right now
      return self.all[0];
    },

    get defaultCoolerId() {
      return self.list.length > 1 ? null : self.list[0]?.id;
    },

    getCoolerById(id) {
      return self.list.find(cooler => cooler.id === id);
    },

    get allCoolerIds() {
      return self.all.map(cooler => cooler.id);
    },
  }))
  .actions(self => ({
    fetch: flow(function* () {
      try {
        self.state = 'pending';

        const response = yield api.getCoolers();

        if (response?.data?.result) {
          self.all.replace(response.data.result);
          self.cooler = response.data.result[0]?.id;
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
          return response.data.result;
        } else {
          self.isLoaded = false;
          self.state = 'done';
          self.updated = new Date();
        }
      } catch (error) {
        self.isLoaded = false;
        self.state = 'error';
        self.updated = new Date();
        console.error(error);
        return Promise.reject(error);
      }
    }),

    setCooler: id => {
      if (Number.isInteger(id)) {
        self.cooler = id;
      }
    },

    createCooler: flow(function* (body) {
      try {
        const response = yield api.createCooler(body);
        const newCooler = response.data?.row;

        self.all.replace([...self.all, newCooler]);

        return newCooler;
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),
    patchCooler: flow(function* (id, body) {
      try {
        const response = yield api.patchCooler(body, id);
        const updatedCooler = response.data?.row;

        self.all.replace([...self.all.filter(c => c.id !== updatedCooler.id), updatedCooler]);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    setCoolers(coolers) {
      self.all.replace(coolers);
      if (!self.selectedCooler && coolers.length > 0) {
        self.selectedCooler = coolers.sort((a, b) => a.name.localeCompare(b.name))[0]?.id;
      }
      self.updated = new Date();
    },

    setSelectedCooler(id) {
      self.selectedCooler = id;
    },
  }));
