import { useCallback } from 'react';
import { useFiltersContext } from 'hooks';

const useFilters = () => {
  const context = useFiltersContext();

  const openFilters = useCallback(() => {
    context.setOpen(true);
  }, [context]);

  const closeFilters = useCallback(() => {
    context.setOpen(false);
  }, [context]);

  return {
    open: context.open,
    openFilters,
    closeFilters,
  };
};

export default useFilters;
