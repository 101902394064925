import { types } from 'mobx-state-tree';

const Rule = types.model({
  action: types.optional(types.string, 'manage', [undefined, null]),
  subject: types.optional(types.string, 'all', [undefined, null]),
});

const ACLRuleSet = types.model({
  priority: types.number,
  ruleset: types.array(Rule),
});

export const UserRole = types.model({
  _establishment_id: types.number,
  _establishment_name: types.string,
  _roleuser_id: types.number,
  _role_id: types.number,
  _role_name: types.optional(types.string, '', [undefined, null]),
  _default_roleuser: types.optional(types.boolean, false, [undefined, '', null]),
  _role_aclrules: types.optional(types.array(ACLRuleSet), [], [undefined, null]),
  _roleuser_accepted_at: types.maybeNull(types.string),
  _roleuser_locked: types.maybeNull(types.boolean),
  _roleuser_locked_by: types.maybeNull(types.number),
  _roleuser_locked_at: types.maybeNull(types.string),
  _valid: types.boolean,
});
