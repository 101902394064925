import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import { styled, alpha } from '@mui/material';

import Tabs from 'components/shared/Tabs';

const TopNavigation = () => {
  const location = useLocation();
  const tabs = [
    {
      path: '/on-tap',
      label: 'On-Tap',
    },
    {
      path: '/inventory',
      label: 'Inventory',
    },
  ];

  const match =
    ['/on-tap', '/inventory', '/inventory/cooler', '/inventory/ordering'].includes(
      location.pathname,
    ) && !Boolean(location?.state?.id);

  return (
    <StyledRootBox className={'TopNavigation'} sx={{ visibility: match ? 'visible' : 'hidden' }}>
      <Tabs isNativeView tabs={tabs} isNavTabs className="tabs" isFullWidth />
    </StyledRootBox>
  );
};

export default TopNavigation;

const StyledRootBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 60,
  boxShadow: 'none',
  left: 0,
  width: '100%',
  '& .MuiTouchRipple-child': {
    backgroundColor: alpha(theme.palette.white, 0.5),
  },
}));
