import { flow, types } from 'mobx-state-tree';

import api from 'services/API';
import { getRootStore } from 'models/root';
import { Establishment } from 'models/types';
import { onTapTileLayout } from 'config/establishment';

export const establishmentInitialState = {
  establishment: {
    id: null,
    name: null,
    physical_address: null,
    phone_number: null,
    archived: false,
    metadata: {
      category: [],
      cleaning_method: null,
      target_flow_rate: null,
      target_line_temp: null,
      target_cooler_temp: null,
      cleaning_rinse_method: null,
      target_flow_rate_unit: null,
      default_keg_expiration: null,
      cleaning_target_duration: null,
      draft_system_cleaning_interval: null,
      unit_preference: 'English',
      digital_menu: null,
      bus_practice_ars_multiple: false,
      ontap_tile_layout: onTapTileLayout.Default,
    },
    menu_active: false,
    menu_metadata: null,
    image_url: null,
    type_code: null,
    draft_system_cleaning_interval_hours: null,
    default_keg_expiration_days: null,
    integration_id: null,
    default_pour_cost_pct: 20,
    display_name: null,
    default_head_space_pct: null,
    pos_hour_integration_done: null,
    pos_modifiers: false,
    rpts_daily_deliver: false,
    rpts_daily_hour: null,
    rpts_weekly_deliver: false,
    time_zone: null,
    hours_of_operation: null,
    _pg_timezone_names_utc_offset: { hours: 0 },
  },
  isLoaded: false,
  isSystemOnline: true,
  hasSystemUpdated: false,
  systemLastUpdated: new Date(),
  processingFields: {
    unit_preference: false,
    default_keg_expiration_days: false,
    day_week_begins: false,
    default_pour_cost: false,
    draft_system_cleaning_interval_hours: false,
    cleaning_target_duration: false,
    pos_cloud_based: false,
    rpts_daily_hour: false,
    pos_hour_integration_done: false,
    integration_id: false,
    default_head_space_pct: false,
    ontap_tile_layout: onTapTileLayout.Default,
  },
};

export const establishmentModel = types
  .model({
    establishment: types.maybeNull(Establishment),
    isLoaded: types.boolean,
    isSystemOnline: types.boolean,
    hasSystemUpdated: types.boolean,
    systemLastUpdated: types.maybeNull(types.Date),
    processingFields: types.model({
      unit_preference: false,
      default_keg_expiration_days: false,
      day_week_begins: false,
      default_pour_cost_pct: false,
      draft_system_cleaning_interval_hours: false,
      cleaning_target_duration: false,
      pos_cloud_based: false,
      rpts_daily_hour: false,
      rpts_weekly_hour: false,
      pos_hour_integration_done: false,
      integration_id: false,
      default_head_space_pct: false,
      ontap_tile_layout: onTapTileLayout.Default,
    }),
  })
  .views(self => ({
    get ID() {
      return self.establishment.id;
    },
    get UUID() {
      return self.establishment.uuid;
    },
    get kegExpiration() {
      return (
        self.establishment.default_keg_expiration_days ||
        self.establishment.metadata.default_keg_expiration
      );
    },

    get cleaningFrequency() {
      return (
        self.establishment.draft_system_cleaning_interval_hours ||
        self.establishment.metadata.draft_system_cleaning_interval
      );
    },
    get cleaningFrequencyInDays() {
      return self.cleaningFrequency / 24;
    },
    get targetDuration() {
      return (
        self.establishment.cleaning_target_duration ||
        self.establishment.metadata.cleaning_target_duration
      );
    },
    get unitPreference() {
      return self.establishment.metadata.unit_preference;
    },
    get digitalMenu() {
      return self.establishment.menu_metadata || {};
    },
    get dayWeekBegins() {
      if (!self.establishment.day_week_begins) {
        return 2;
      }
      return self.establishment.day_week_begins;
    },
    get pourCost() {
      return self.establishment.default_pour_cost_pct;
    },
    get headPercentage() {
      return self.establishment.default_head_space_pct;
    },
    get posIntegrationDone() {
      if (!self.establishment.pos_hour_integration_done) {
        return new Date().setHours(10, 0, 0, 0);
      }

      return new Date().setHours(self.establishment.pos_hour_integration_done, 0, 0, 0);
    },
    get tempUnit() {
      return self.establishment.metadata.unit_preference ===
        establishmentInitialState.establishment.metadata.unit_preference
        ? '°F'
        : '°C';
    },
    get tileLayoutTypeId() {
      return self.establishment.metadata.ontap_tile_layout;
    },
    getTileLayoutName(id) {
      return onTapTileLayout[id || self.establishment.metadata.ontap_tile_layout];
    },
    getTileLayoutDescription(id) {
      switch (id || self.establishment.metadata.ontap_tile_layout) {
        case 1:
          return "The default version displays keg levels as a total percentage of the keg's volume. This version is used for inventory-focused app users who prefer a visual representation of their keg levels.";

        case 2:
          return 'This version is implemented by app users who do not use BarTrack for inventory purposes. There is no keg-level representation. The volume poured is captured in the BarTrack reports or inventory section of the app.';
        case 3:
          return 'This version incorporates the keg level percentage and the total full keg stock on hand. This version is ideal for app users who prefer to see their entire inventory, including partials, on the on-tap page. This version is not recommended for establishments that serve the same beverage across multiple tap lines.';
        default:
          return '';
      }
    },
  }))
  .actions(self => {
    return {
      fetch: flow(function* (force = false) {
        if (self.isLoaded && !force) {
          return self.establishment;
        } else {
          const root = getRootStore();
          self.isLoaded = false;
          try {
            const establishment = yield api.getEstablishment({
              id: root.userStore.currentRole._establishment_id,
            });
            self.establishment = establishment.data.result;
            self.isLoaded = true;
            return establishment.data.result;
          } catch (err) {
            return Promise.reject(err);
          }
        }
      }),
      patch: flow(function* (patch) {
        const root = getRootStore();
        let fields;
        try {
          fields = Object.keys(patch);
          for (const field of fields) {
            self.processingFields[field] = true;
          }
          const updatedEstablishment = yield api.patchEstablishment(patch, {
            id: root.userStore.currentRole._establishment_id,
          });
          self.establishment = updatedEstablishment.data.row;
          return updatedEstablishment;
        } catch (err) {
          return Promise.reject(err);
        } finally {
          for (const field of fields) {
            self.processingFields[field] = false;
          }
        }
      }),
      patchMetadata: flow(function* (metadata) {
        try {
          const updatedEstablishment = yield self.patch({
            metadata: {
              ...self.establishment.metadata,
              ...metadata,
            },
          });
          self.establishment = updatedEstablishment.data.row;
          return updatedEstablishment;
        } catch (err) {
          return Promise.reject(err);
        }
      }),
      patchMenuMetadata: flow(function* (menu_metadata) {
        try {
          const updatedEstablishment = yield self.patch({
            menu_metadata: {
              ...self.establishment.menu_metadata,
              ...menu_metadata,
            },
          });
          self.establishment = updatedEstablishment.data.row;
          return updatedEstablishment;
        } catch (err) {
          return Promise.reject(err);
        }
      }),
      setEstablishment(establishment) {
        self.establishment = establishment;
        self.isLoaded = true;
      },
      updateDigitalMenuField(field, value) {
        if (field in self.digitalMenu) {
          self.digitalMenu[field] = value;
        }
      },
      sendReport: flow(function* (data) {
        const { establishmentStore } = getRootStore();
        const id =
          establishmentStore &&
          establishmentStore.establishment &&
          establishmentStore.establishment.id;
        try {
          return yield api.sendReport({
            ...data,
            establishment_id: id,
          });
        } catch (err) {
          return Promise.reject(err);
        }
      }),
      handleHeartbeat(result) {
        if (!result[0]) return;

        const { received_at } = result[0];
        self.hasSystemUpdated = true;
        self.systemLastUpdated = new Date(received_at);
      },
      setSystemStatus(value) {
        self.isSystemOnline = value;
      },
    };
  });
