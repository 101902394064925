import { colors } from '@mui/material';
import { alpha } from '@mui/material/styles';

const black = '#000';
const white = '#fff';

const greyDark = '#151515';
const grey = '#282626';
const greyLight = '#212121';

const palette = {
  black,
  white,
  whiteEmphasis: {
    high: '#E1E1E1',
    medium: '#A1A1A1',
    low: 'rgba(255, 255, 255, 0.38)',
  },
  outline: {
    main: 'rgba(255, 255, 255, 0.12)',
  },
  background: {
    paper: '#1F1D1D',
    main: greyDark,
    default: grey,
    filters: '#282828',
    overlay0: 'rgba(255, 255, 255, 0.03)',
    overlay1: 'rgba(255, 255, 255, 0.05)',
    overlay2: 'rgba(255, 255, 255, 0.07)',
    overlay3: 'rgba(255, 255, 255, 0.08)',
    overlay4: 'rgba(255, 255, 255, 0.09)',
    overlay5: 'rgba(255, 255, 255, 0.11)',
    overlay6: 'rgba(255, 255, 255, 0.12)',
    overlay7: 'rgba(255, 255, 255, 0.14)',
    overlay8: 'rgba(255, 255, 255, 0.15)',
    overlay9: 'rgba(255, 255, 255, 0.16)',
  },
  shadow: {
    shadow1: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    shadow2: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    shadow3: '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
    shadow4: '0px 2px 3px 0px rgba(0, 0, 0, 0.30), 0px 6px 10px 4px rgba(0, 0, 0, 0.15)',
    shadow5: '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.30)',
  },
  primary: {
    contrastText: black,
    dark: '#bb5800',
    main: '#ff7A00',
    light: '#FFCE44',
    hover: '#FF8A1F',
    focused: '#FF9B40',
    pressed: '#FFA24D',
    disabled: '#313131',
    overlay1: '#1E1914', // 4%
    overlay2: '#2C1F13', // 10%
    overlay3: '#312112', // 12%
    overlay4: '#442911', // 20%
  },
  secondary: {
    contrastText: white,
    dark: greyDark,
    main: grey,
    greyLight,
    light: '#4F4F4F',
    lightest: '#C4C4C4',
  },
  error: {
    contrastText: white,
    dark: '#a80000',
    main: '#ff0000',
    light: '#F44336',
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  info: {
    contrastText: white,
    main: '#0BC207',
    green: '#43A047',
    yellow: '#FFA726',
    red: '#DE1A39',
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  text: {
    primary: white,
    secondary: alpha(white, 0.6),
    disabled: '#6e6e6e',
    hint: alpha(white, 0.5),
    icon: alpha(white, 0.5),
  },
  divider: alpha(white, 0.2),
  temperature: {
    white: white,
    red: '#F44336',
    blue: '#2196F3',
  },
  status: {
    green: '#66BB6A',
    red: '#FF7A7A',
  },
};

export default palette;
