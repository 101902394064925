import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import { Drawer, Box, IconButton, Typography, Tabs, Tab, styled } from '@mui/material';
import { ArrowBackIos, ArrowDropDown } from '@mui/icons-material';
import { differenceInMinutes } from 'date-fns';

import { useStore } from 'hooks';

import OnlineStatus from 'components/shared/OnlineStatus';

import Chart from '../Chart';
import PeriodButtonGroup from '../Chart/PeriodButtonGroup';

import GeneralTab from './GeneralTab';
import SelectSensorMenuDrawer from './SelectSensorMenuDrawer';
import SensorCalibrationTab from './SensorCalibrationTab';

const SensorDetailsDrawer = ({ sensor, open, onClose }) => {
  const { topologyManagementStore, userStore } = useStore();

  const [currentSensor, setCurrentSensor] = useState(sensor);
  const bru = topologyManagementStore.getBruBySensorId(currentSensor?.id);

  const [activeTab, setActiveTab] = useState('general');
  const [openSelectSensorMenu, setSelectSensorMenu] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleOpenSelectSensorMenu = () => {
    setSelectSensorMenu(true);
  };

  const handleCloseSensorMenu = () => {
    setSelectSensorMenu(false);
  };

  useEffect(() => {
    topologyManagementStore.setSelectedGatewayId(currentSensor?._gateways_id);
    const gateway = topologyManagementStore.gateways.find(
      g => g.id === currentSensor?._gateways_id,
    );
    const bru = topologyManagementStore.brus.find(b => b.id === currentSensor?.bru_id);

    if (
      differenceInMinutes(new Date(), new Date(currentSensor.latest_heartbeat_received_at)) <= 15
    ) {
      topologyManagementStore.fetchSensorDiagnosticsInfo({
        gateway_identifier: gateway?.identifier,
        gateway_bru_address: bru?.gateway_bru_address,
        bru_sensor_address: currentSensor?.bru_sensor_address,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSensor?.id]);

  return (
    <StyledRootDrawer open={open} onClose={onClose} anchor="right">
      <Box className="header">
        <IconButton onClick={onClose}>
          <ArrowBackIos />
        </IconButton>
        <Box className="title_box" onClick={handleOpenSelectSensorMenu}>
          <Typography variant="h7">Sensor #{currentSensor?.bru_sensor_address}</Typography>
          <ArrowDropDown />
        </Box>
        <OnlineStatus
          date={sensor?.latest_heartbeat_received_at}
          replaced={bru?.replacementDetected}
        />
      </Box>
      <Box className="body">
        <Box className="info_box">
          <Box>
            <Typography className="subtitle">Sensor Serial #</Typography>
            <Typography variant="h7">{currentSensor?.id}</Typography>
          </Box>
          <Box>
            <Typography className="subtitle">Parent Device</Typography>
            <Typography variant="h7">BRU #{bru?.gateway_bru_address}</Typography>
          </Box>
          <Box>
            <Typography className="subtitle">BRU Serial #</Typography>
            <Typography variant="h7">{bru?.id}</Typography>
          </Box>
        </Box>
        <Box className="chart_box">
          <Typography mb={2}>Connectivity</Typography>
          <PeriodButtonGroup className="period_block" />
          <Chart
            type="sensors"
            device={currentSensor}
            loading={!topologyManagementStore.isLoaded}
          />
        </Box>
        <Box className="tab_box">
          <Tabs
            value={activeTab}
            className="tabs"
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChangeTab}
          >
            <Tab label="General" value="general" />
            <Tab label="Replacement History" value="replacement_history" disabled />
            <Tab
              label="Sensor Calibration"
              value="sensor_calibration"
              disabled={!userStore.isBarTrackUser}
            />
          </Tabs>
          {activeTab === 'general' && <GeneralTab sensor={currentSensor} />}
          {/* {activeTab === 'replacement_history' && <ReplacementHistoryTab />}*/}
          {activeTab === 'sensor_calibration' && <SensorCalibrationTab sensor={currentSensor} />}
        </Box>
      </Box>

      <SelectSensorMenuDrawer
        currentSensor={currentSensor}
        bru={bru}
        open={openSelectSensorMenu}
        onClose={handleCloseSensorMenu}
        setCurrentSensor={setCurrentSensor}
      />
    </StyledRootDrawer>
  );
};

export default observer(SensorDetailsDrawer);

const StyledRootDrawer = styled(Drawer)(({ theme: { palette } }) => ({
  '& .MuiDrawer-paper': {
    width: '100%',
    backgroundColor: palette.background.main,
  },

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  '.header': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    borderBottom: `1px solid ${palette.outline.main}`,
    padding: '16px',

    '.title_box': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '.subtitle': {
      fontSize: '12px',
      color: palette.whiteEmphasis.medium,
    },

    '.status_icon': {
      position: 'absolute',
      top: '20px',
      right: '16px',
      marginRight: '12px',
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: palette.status.red,
      '&.active': {
        backgroundColor: palette.status.green,
      },
    },
  },

  '.body': {
    padding: '16px 16px 0',
    height: '100%',
    overflow: 'auto',

    '.info_box': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },

    '.subtitle': {
      fontSize: '12px',
      color: palette.whiteEmphasis.medium,
    },

    '.edit_icon': {
      fill: palette.whiteEmphasis.medium,
    },

    '.chart_box': {
      borderRadius: '8px',
      backgroundColor: palette.secondary.greyLight,
      padding: '16px 8px',
    },

    '.period_block': {
      padding: 5,
      backgroundColor: palette.secondary.dark,
      display: 'flex',
      justifyContent: 'center',
      button: {
        minWidth: '50px',
        width: '-webkit-fill-available',
      },
    },

    '.tab_box': {
      backgroundColor: palette.secondary.greyLight,
      margin: '8px -16px 0px',
      padding: '0 16px 16px',
    },

    '.tabs': {
      '& .MuiTab-root': {
        textTransform: 'none',
      },
    },
  },

  '.footer': {
    padding: '16px',
    display: 'flex',
    width: '100%',
    backgroundColor: palette.background.main,

    '& > button': {
      width: '100%',
    },
  },
}));
