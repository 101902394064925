import { flow, types } from 'mobx-state-tree';

import { Report } from 'models/types';
import api from 'services/API';

export const reportInitialState = {
  isLoaded: false,
  report_configuration: {
    archived: false,
    auto_deliver_daily: false,
    auto_deliver_daily_hour: 0,
    auto_deliver_monthly: false,
    auto_deliver_monthly_day: 0,
    auto_deliver_monthly_hour: 0,
    auto_deliver_weekly: false,
    auto_deliver_weekly_day: 0,
    auto_deliver_weekly_hour: 0,
    benchmark_efficiency: 0,
    inventory_consumption_days: 0,
    combine_voids_and_comps: false,
    day_week_begins: 0,
    establishment_id: 0,
    hourly_breakdown_combine_sales_and_comps: false,
    hourly_breakdown_exclude_overlay: false,
    hourly_breakdown_exclude_sales: false,
    id: 0,
    reports_valid_from: null,
    updated_at: null,
    updated_by: 0,
    volume_only: false,
    zero_dollar_comps: true,
    _establishments_name: null,
    _users_display_name_updated_by: null,
    report_delivery_status_code: 0,
    delivery_info: {
      daily_report_recipients: [],
      monthly_report_recipients: [],
      weekly_report_recipients: [],
    },
    report_thresholds: {
      comp_threshold_pct: 0,
      efficiency_threshold_pct: 0,
      human_threshold_pct: 0,
      max_target_efficiency_pct: 0,
      min_target_efficiency_pct: 0,
      overpour_threshold_pct: 0,
      pourcost_threshold_pct: 0,
      quality_threshold_pct: 0,
      system_threshold_pct: 0,
      underpour_threshold_pct: 0,
    },
    rotation: {
      hours: 0,
    },
    daily_report_format: {
      hourly_breakdown: false,
      first_page_summary: false,
    },
    weekly_report_format: {
      first_page_summary: false,
    },
    monthly_report_format: {
      first_page_summary: false,
    },
  },
  // Update values if it needed
  processingFields: {
    day_week_begins: false,
  },
};

export const reportModel = types
  .model({
    report_configuration: types.maybeNull(Report),
    isLoaded: types.boolean,
    processingFields: types.model({
      day_week_begins: types.boolean,
    }),
  })
  .views(self => ({
    get dayWeekBegins() {
      if (!self.report_configuration.day_week_begins) {
        return 2;
      }
      return self.report_configuration.day_week_begins;
    },
    get deliveryInfo() {
      return self.report_configuration?.delivery_info;
    },
  }))
  .actions(self => {
    return {
      fetch: flow(function* () {
        if (self.isLoaded) {
          return self.establishment;
        } else {
          self.isLoaded = false;
          try {
            const report_configuration = yield api.getReport();
            self.report_configuration = report_configuration.data.result[0];
            self.isLoaded = true;
            return report_configuration.data.result[0];
          } catch (err) {
            return Promise.reject(err);
          }
        }
      }),

      patch: flow(function* (data) {
        try {
          for (const field of Object.keys(data)) {
            self.processingFields[field] = true;
          }
          const updatedReportConfiguration = yield api.patchReport(data, { rows: 1 });
          self.report_configuration = updatedReportConfiguration.data.result[0];
          return updatedReportConfiguration;
        } catch (err) {
          return Promise.reject(err);
        } finally {
          for (const field of Object.keys(data)) {
            self.processingFields[field] = false;
          }
        }
      }),

      setReportConfiguration(report_configuration) {
        self.report_configuration = report_configuration;
        self.isLoaded = true;
      },
    };
  });
