import {
  format,
  addHours,
  addDays,
  subHours,
  subDays,
  subWeeks,
  subMonths,
  differenceInSeconds,
  isDate,
  formatDuration,
  intervalToDuration,
  isValid,
  isToday,
  isYesterday,
  startOfDay,
  startOfYesterday,
  startOfWeek,
  startOfMonth,
  startOfYear,
} from 'date-fns';

import { getRootStore } from 'models/root';
import { DAY_MS, HOUR_MS, MINUTE_MS, OFFLINE_TIME_MAX_MS } from 'config/constants';

const dateUtilities = {
  convertUTCToLocal: timeHM =>
    format(new Date(`${new Date().toISOString().split('T')[0]}T${timeHM}:00.000Z`), 'p'),

  convertToken: timeHM =>
    format(new Date(`${new Date().toISOString().split('T')[0]}T${timeHM}:00.000`), 'p'),

  getDateInDays: days => {
    const startDate = new Date();
    const endDate = startDate.setDate(startDate.getDate() + days);
    return format(new Date(endDate), 'y-MM-d');
  },

  makeAgeDaysHours: startDate => {
    const difference = differenceInSeconds(new Date(new Date().toISOString()), new Date(startDate));
    const days = Math.floor(difference / 86400);
    const leftSeconds = difference % 86400;
    const hours = Math.floor(leftSeconds / 3600);
    return `${days}d ${hours}h`;
  },

  getTimestampToNowInSeconds: startDate => {
    return differenceInSeconds(
      addHours(new Date(), new Date().getTimezoneOffset() / 60),
      new Date(startDate),
    );
  },

  makeDateFormatMD: date => {
    return date ? format(new Date(date).getTime(), 'M/d') : 'N/A';
  },

  makeDuration: (startDate, finishDate) => {
    if (!startDate || !finishDate) {
      return '-';
    }
    const difference = differenceInSeconds(new Date(finishDate), new Date(startDate));
    if (difference && Number.isInteger(difference) && difference < 0) {
      return '-';
    }
    if (difference > 86400) {
      const days = Math.floor(difference / 86400);
      const leftSeconds = difference % 86400;
      const hours = Math.floor(leftSeconds / 3600);
      return `${days}d ${hours}h`;
    }
    const leftSecondsForMinutes = difference % 3600;
    const seconds = difference % 60;
    const hours = Math.floor(difference / 3600);
    const minutes = Math.floor(leftSecondsForMinutes / 60);
    if (hours && hours > 0) {
      return `${hours}h ${minutes}m`;
    }
    if (minutes && minutes > 0) {
      return `${minutes}m`;
    }
    if (seconds && seconds > 0) {
      return `${seconds}s`;
    }
    if (seconds === 0) {
      return `${seconds}s`;
    }
    return '-';
  },

  formatDate: date => {
    if (!date || !isDate(new Date(date))) {
      return '-';
    }
    return format(new Date(date), 'h:mm a');
  },

  getDifference: (startDate, finishDate) => {
    return differenceInSeconds(new Date(finishDate), new Date(startDate));
  },

  makeDateFormatMDYYYY: date => {
    return date ? format(new Date(date).getTime(), 'M/d/u') : null;
  },

  makeDateFormatMDYY: date => {
    return date ? format(new Date(date).getTime(), 'M/d/yy') : null;
  },

  durationToSeconds: duration => {
    const { days, hours, minutes, seconds } = duration;
    let total = 0;
    if (days) total += days * 24 * 60 * 60;
    if (hours) total += hours * 60 * 60;
    if (minutes) total += minutes * 60;
    if (seconds) total += seconds;

    return total;
  },

  secondsToDuration: seconds => {
    if (!seconds) return '0s';
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
    const { months, days, hours, minutes } = duration;

    const format = months
      ? ['months', 'days']
      : days
      ? ['days', 'hours']
      : hours > 0
      ? ['hours', 'minutes']
      : minutes > 0
      ? ['minutes', 'seconds']
      : ['seconds'];

    return formatDuration(duration, { format })
      .replace(' years', 'y')
      .replace(' year', 'y')
      .replace(' months', 'mo')
      .replace(' month', 'mo')
      .replace(' days', 'd')
      .replace(' day', 'd')
      .replace(' hours', 'h')
      .replace(' hour', 'h')
      .replace(' minutes', 'm')
      .replace(' minute', 'm')
      .replace(' seconds', 's')
      .replace(' second', 's');
  },

  convertUtcToZonedTime: (date, fallback = null) => {
    const root = getRootStore();

    // * Version where we use _pg_timezone_names_utc_offset - probably more stable
    if (isValid(new Date(date))) {
      return addHours(
        new Date(date),
        root.establishmentStore.establishment?._pg_timezone_names_utc_offset.hours,
      );
    }

    // * Version where we use utcToZonedTime func
    // if (isValid(new Date(date))) {
    //   return utcToZonedTime(new Date(date), root.establishmentStore.establishment.time_zone);
    // }
    return fallback;
  },

  convertUTCToZonedTime: (date, fallback = null) => {
    const root = getRootStore();

    if (isValid(new Date(date))) {
      return addHours(
        new Date(date.slice(0, -1)),
        root.establishmentStore.establishment?._pg_timezone_names_utc_offset.hours,
      );
    }

    return fallback;
  },

  convertZonedToUtcTime: (date, fallback = null) => {
    const root = getRootStore();

    if (isValid(new Date(date))) {
      return subHours(
        new Date(date),
        root.establishmentStore.establishment?._pg_timezone_names_utc_offset.hours,
      );
    }

    return fallback;
  },

  convertTimeToEstablishmentOffset: date => {
    const root = getRootStore();

    if (isValid(new Date(date))) {
      const utc_offset = new Date().getTimezoneOffset() / 60;
      const establishmentOffset =
        root.establishmentStore.establishment?._pg_timezone_names_utc_offset.hours;

      const d = new Date(date);
      return new Date(d.setHours(d.getHours() + utc_offset + establishmentOffset));
    }

    return null;
  },

  timeZoneShortNameIfDiff: (start = ' (', end = ')') => {
    const root = getRootStore();

    var formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: root?.establishmentStore?.establishment.time_zone,
      timeZoneName: 'short',
    });
    const shortDate = formatter.format(new Date());
    const [, timeZoneShortName] = shortDate.split(', ');
    return start + timeZoneShortName + end;
  },

  makeDateFormatddMMMMyyyy: date => {
    const root = getRootStore();

    let convertedDate;
    if (isValid(new Date(date))) {
      convertedDate = subHours(
        new Date(date),
        root.establishmentStore.establishment?._pg_timezone_names_utc_offset.hours,
      );
    }
    return convertedDate ? format(new Date(convertedDate).getTime(), 'dd MMMM yyyy') : null;
  },

  formatDateIncludingToday: (rawDate, options) => {
    const {
      fallback = 'N/A',
      withTimezone = true,
      dateFormat = 'M/d',
      applyEstablishmentOffset = true,
    } = options || {};

    let date = new Date(rawDate);

    if (!rawDate || date.toString() === 'Invalid Date') return fallback;

    const root = getRootStore();

    var formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: root?.establishmentStore?.establishment.time_zone,
      timeZoneName: 'short',
    });
    const shortDate = formatter.format(new Date());

    const [, timeZoneShortName] = shortDate.split(', ');
    const timeZoneShortNameIfDiff = withTimezone ? ` (${timeZoneShortName})` : '';

    if (applyEstablishmentOffset) {
      date = addHours(
        new Date(date),
        root.establishmentStore.establishment?._pg_timezone_names_utc_offset.hours,
      );
    }

    if (isToday(new Date(date))) {
      return `Today at ${format(new Date(date), 'h:mm a')} ${timeZoneShortNameIfDiff}`;
    } else if (isYesterday(new Date(date))) {
      return `Yesterday at ${format(new Date(date), 'h:mm a')} ${timeZoneShortNameIfDiff}`;
    } else {
      return `${format(new Date(date), dateFormat)} at ${format(
        new Date(date),
        'h:mm a',
      )} ${timeZoneShortNameIfDiff}`;
    }
  },
  makeDisplayedTime: value => {
    if (!value) return 'N/A';
    return value.days
      ? `${value.days}d ${value.hours ? value.hours + 'h' : 0 + 'h'}`
      : value.hours
      ? `${value.hours}h ${value.minutes ? value.minutes + 'm' : 0 + 'm'}`
      : value.minutes
      ? `${value.minutes}m ${value.seconds ? value.seconds + 's' : 0 + 's'}`
      : value.seconds
      ? `${value.seconds}s`
      : value.milliseconds
      ? `${Math.round(value.milliseconds)}ms`
      : 'N/A';
  },

  parseTimeStringToSeconds: timeStr => {
    if (!timeStr) return timeStr;

    const str = timeStr.replace('days', 'day');

    const regex = new RegExp('(([0-9]*) day )?([0-9]{1,3}):([0-9]{2}):([0-9.]*)', 'g');
    let result = null;
    const matched = regex.exec(str);

    if (Array.isArray(matched)) {
      const [days, hours, minutes, seconds] = matched.slice(2);

      result =
        24 * 3600 * (days || 0) + 3600 * Number(hours) + 60 * Number(minutes) + Number(seconds);
    }

    return result;
  },

  formatDateTimeLocal: (rawDate, options) => {
    if (!rawDate) return rawDate;

    let date = new Date(rawDate);
    const {
      fallback = 'N/A',
      withTimezone = true,
      applyEstablishmentOffset = true,
    } = options || {};
    if (!date) return fallback;

    const root = getRootStore();

    var formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: root?.establishmentStore?.establishment.time_zone,
      timeZoneName: 'short',
    });
    const shortDate = formatter.format(new Date());

    const [, timeZoneShortName] = shortDate.split(', ');
    const timeZoneShortNameIfDiff = withTimezone ? `${timeZoneShortName}` : '';

    const localeOffset = new Date().getTimezoneOffset() / 60;

    if (applyEstablishmentOffset) {
      date = addHours(
        new Date(date),
        localeOffset + root.establishmentStore.establishment?._pg_timezone_names_utc_offset.hours,
      );
    }

    return `${format(date, 'MMM d, h:mm a')} (${timeZoneShortNameIfDiff})`;
  },

  isOnline: value => {
    if (!value) return false;

    if (typeof value === 'number') return value * 1000 < OFFLINE_TIME_MAX_MS; // expect number of seconds ago

    if (!Number.isNaN(Date.parse(value)))
      return Date.now() - Date.parse(value) < OFFLINE_TIME_MAX_MS; // expect ISO date string

    return false;
  },
  getMostRecentDate: dates =>
    dates
      .filter(timestamp => new Date(timestamp).getTime() !== null)
      .reduce((min, timestamp) => (min === null || timestamp > min ? timestamp : min), null),

  getYesterday: () => new Date(Date.now() - DAY_MS + new Date().getTimezoneOffset() * MINUTE_MS),

  revertOffsets: date => {
    if (!date) return date;
    const root = getRootStore();
    const {
      rotation: { hours },
    } = root.reportStore.report_configuration;
    const establishmentOffset =
      root.establishmentStore.establishment?._pg_timezone_names_utc_offset.hours;

    const localeOffsetMS = new Date().getTimezoneOffset() * MINUTE_MS;
    const rotationMS = hours ? hours * HOUR_MS : 0;
    const estOffsetMS = establishmentOffset ? establishmentOffset * HOUR_MS : 0;

    return new Date(Date.parse(date) + localeOffsetMS - rotationMS + estOffsetMS);
  },

  getDatesByPeriod: (period = 'yesterday', { fromDate = null, toDate = null } = {}) => {
    const periods = [
      'today',
      'yesterday',
      'this-week',
      'last-week',
      'this-month',
      'last-month',
      'ytd',
      'custom',
      '1',
      '7',
      '14',
      '30',
      'inventory_consumption_days',
    ];
    if (!periods.includes(period)) return { from: null, to: null };

    const root = getRootStore();
    const {
      day_week_begins,
      rotation: { hours },
      inventory_consumption_days,
    } = root.reportStore.report_configuration;
    const establishmentOffset =
      root.establishmentStore.establishment?._pg_timezone_names_utc_offset.hours;

    const localeOffsetMS = new Date().getTimezoneOffset() * MINUTE_MS;
    const rotationMS = hours ? hours * HOUR_MS : 0;
    const estOffsetMS = establishmentOffset ? establishmentOffset * HOUR_MS : 0;
    const typeHandlers = {
      today: startOfDay,
      yesterday: startOfYesterday,
      'this-week': startOfWeek,
      'last-week': startOfWeek,
      'this-month': startOfMonth,
      'last-month': startOfMonth,
      ytd: startOfYear,
      1: startOfDay,
      7: startOfDay,
      14: startOfDay,
      30: startOfDay,
      inventory_consumption_days: startOfDay,
    };
    const isNumberPeriod = ['1', '7', '14', '30'].includes(period);

    const handleOffsets = date => {
      return new Date(Date.parse(date) - localeOffsetMS + rotationMS - estOffsetMS).toISOString();
    };

    const getInitDate = () => {
      const fn = typeHandlers[period];
      if (!fn) return null;

      let options = {};

      if (period.includes('week')) {
        const daysOfWeekIndexes = [1, 7, 1, 2, 3, 4, 5, 6];
        const dayIndex = daysOfWeekIndexes[day_week_begins];
        options = { weekStartsOn: dayIndex };
      }

      return handleOffsets(fn(Date.now(), options));
    };
    const now = new Date();

    let from = isNumberPeriod ? null : getInitDate();
    let to = isNumberPeriod ? getInitDate() : null;

    if (period === 'yesterday') {
      to = addDays(from, 1).toISOString();
    }

    if (period === 'last-week') {
      to = from;
      from = subWeeks(from, 1).toISOString();
    }

    if (period === 'last-month') {
      to = from;
      from = subMonths(from, 1).toISOString();
    }

    if (period === 'custom') {
      from = fromDate ? handleOffsets(startOfDay(fromDate)) : null;
      to = toDate ? handleOffsets(startOfDay(addDays(toDate, 1))) : null;
    }

    if (period === 'inventory_consumption_days') {
      from = subDays(from, inventory_consumption_days).toISOString();
    }

    if (isNumberPeriod) {
      from = subDays(now, Number(period)).toISOString();
      to = now.toISOString();
    }

    return { from, to };
  },
};

export default dateUtilities;
