import React, { useEffect, useState, useContext, useMemo } from 'react';
import { styled } from '@mui/system';
import { Button, CircularProgress, Typography, Box } from '@mui/material';
import { Refresh, WarningAmberOutlined, Warning, InfoOutlined } from '@mui/icons-material';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import { messages } from 'config/messages';
import { useStore, useToast } from 'hooks';
import { CalibrationContext } from 'context/calibration';
import SensorGauge from 'components/@SystemConfiguration/HardwareManagement/SensorGauge';

const Initial = ({ sensor, goToStep, onClose, readOnly = false }) => {
  const {
    power,
    noise,
    accuracy,
    isSensorCalibrated,
    flowVolumeCalibrationAttempt,
    setIsSensorCalibrated,
    isFlowVolumeCalibrated,
    onSuccessfulCalibration,
    latestCalibrationInfo,
  } = useContext(CalibrationContext);
  const { topologyManagementStore } = useStore();
  const [outOfRange, setOutOfRange] = useState(false);
  const [loading, setLoading] = useState(false);
  const { errorToast } = useToast();

  const handleSensorCalibrateClick = async () => {
    setLoading(true);

    try {
      const gateway = topologyManagementStore
        .getFilteredItemsByKey('gateway')
        .find(gw => gw.id === sensor._gateways_id);
      const bru = topologyManagementStore.getBruBySensorId(sensor?.id);
      const BODY = {
        gateway_identifier: gateway?.identifier,
        gateway_bru_address: bru.gateway_bru_address,
        bru_sensor_address: sensor.bru_sensor_address,
      };

      const calibrationValue = await topologyManagementStore.calibrateSensorValues(BODY);
      setIsSensorCalibrated(true);
      onSuccessfulCalibration(calibrationValue);
    } catch (error) {
      setIsSensorCalibrated(false);
      errorToast(messages.sensor.FETCH_ERROR);
    } finally {
      setLoading(false);
    }
  };

  const calibrationSensorState = isSensorCalibrated ? (
    loading ? (
      <CircularProgress size={20} />
    ) : (
      <Box className="graphs">
        <SensorGauge className="power_gauge" value={power.value} type="power" level={power.level} />
        <SensorGauge className="noise_gauge" value={noise.value} type="noise" level={noise.level} />
      </Box>
    )
  ) : (
    <Button
      color="secondary"
      disabled={loading}
      variant={loading ? 'text' : 'outlined'}
      onClick={handleSensorCalibrateClick}
    >
      {loading ? <CircularProgress size={20} /> : 'Calibrate'}
    </Button>
  );

  const calibrationFlowVolumeState = isFlowVolumeCalibrated ? (
    <Box className="graphs">
      <SensorGauge
        className="flow_gauge"
        type="flow"
        value={accuracy.percentage}
        level={accuracy.level}
      />
    </Box>
  ) : (
    <Button
      color="secondary"
      variant="outlined"
      disabled={!isSensorCalibrated}
      onClick={() => goToStep('BeforeBegin')}
    >
      Calibrate
    </Button>
  );

  const calibrationInfo = useMemo(() => {
    return (
      <Box className={clsx('calibration_info', { hidden: readOnly || !latestCalibrationInfo })}>
        <InfoOutlined />
        <Typography variant="body2">{latestCalibrationInfo}</Typography>
      </Box>
    );
  }, [latestCalibrationInfo, readOnly]);

  useEffect(() => {
    if (!isSensorCalibrated) {
      return;
    }

    if (noise.level !== 'Good' || power.level !== 'Good') {
      setOutOfRange(true);
    }
  }, [noise.level, isSensorCalibrated, power.level]);

  return (
    <StyledRootBox>
      <Box className="content">
        <Box className="section">
          <Box className="section_header">
            <span className="sensor_title">
              <Typography variant="h5">Sensor</Typography>
              {outOfRange && (
                <WarningAmberOutlined
                  sx={({ palette }) => ({
                    color: palette.status.red,
                  })}
                />
              )}
            </span>
            {isSensorCalibrated && (
              <Box
                className={clsx('sensor_recalibrate', { hidden: readOnly })}
                onClick={handleSensorCalibrateClick}
              >
                <Refresh fontSize="small" color="primary" />
                <Typography
                  className="recalibrate_text"
                  variant="body2"
                  color={({ palette }) => palette.primary.main}
                >
                  Recalibrate
                </Typography>
              </Box>
            )}
          </Box>
          <Box className="section_content">
            <Box className="section_info">
              <Box className="info_title">
                <Typography variant="h7">Power</Typography>
                <Typography
                  variant="h7"
                  color={({ palette }) =>
                    power.level === 'Good'
                      ? palette.status.green
                      : loading || !isSensorCalibrated
                      ? palette.whiteEmphasis.medium
                      : palette.status.red
                  }
                >
                  {loading ? 'Calibrating...' : power.level}
                </Typography>
              </Box>
              <Box className="info_title noise">
                <Typography variant="h7">Noise</Typography>
                <Typography
                  variant="h7"
                  color={({ palette }) =>
                    noise.level === 'Good'
                      ? palette.status.green
                      : loading || !isSensorCalibrated
                      ? palette.whiteEmphasis.medium
                      : palette.status.red
                  }
                >
                  {loading ? 'Calibrating...' : noise.level}
                </Typography>
              </Box>
            </Box>
            {calibrationSensorState}
          </Box>
        </Box>
        {outOfRange && (
          <Box className="outOfRange">
            <Box className="warning_icon">
              <Warning
                fontSize="small"
                sx={({ palette }) => ({
                  color: palette.status.red,
                })}
              />
            </Box>
            <Box className="out_of_range_text">
              <Typography
                variant="caption"
                sx={({ palette }) => ({
                  color: palette.status.red,
                })}
              >
                Flow Volume calibration may be affected because the sensor values are outside the
                recommended ranges
              </Typography>
            </Box>
          </Box>
        )}
        <Box className={clsx('section flow_volume', { disabled: !isSensorCalibrated })}>
          <Box className="sensor_heading">
            <span className="sensor_title">
              <Typography className="section_title" variant="h5">
                Flow Volume
              </Typography>
              {flowVolumeCalibrationAttempt > 0 && (
                <WarningAmberOutlined sx={({ palette }) => ({ color: palette.status.red })} />
              )}
            </span>
            {isFlowVolumeCalibrated && (
              <Box
                className={clsx('sensor_recalibrate', { hidden: readOnly })}
                onClick={() => goToStep('BeforeBegin')}
              >
                <Refresh fontSize="small" color="primary" />
                <Typography
                  className="recalibrate_text"
                  variant="body2"
                  color={({ palette }) => palette.primary.main}
                >
                  Recalibrate
                </Typography>
              </Box>
            )}
          </Box>
          <Box className="section_content">
            <Box className="section_info">
              <Box className="info_title">
                <Typography variant="h7">Accuracy</Typography>
                <Typography
                  variant="h7"
                  color={({ palette }) =>
                    accuracy.level === 'Good'
                      ? palette.status.green
                      : loading || !isSensorCalibrated
                      ? palette.whiteEmphasis.medium
                      : palette.status.red
                  }
                >
                  {accuracy.level}
                </Typography>
              </Box>
            </Box>
            {calibrationFlowVolumeState}
          </Box>
          {flowVolumeCalibrationAttempt > 2 && (
            <Box className="outOfRange">
              <Box className="warning_icon">
                <Warning fontSize="small" sx={({ palette }) => ({ color: palette.status.red })} />
              </Box>
              <Box className="out_of_range_text">
                <Typography variant="caption" color={({ palette }) => palette.status.red}>
                  It looks like we're having trouble calibrating this sensor. We recommend skipping
                  it for now and trying again later. Would you like to skip or continue with the
                  calibration?
                </Typography>
              </Box>
            </Box>
          )}
          {calibrationInfo}
        </Box>
      </Box>

      <Box className={clsx('action_box', { hidden: readOnly })}>
        {flowVolumeCalibrationAttempt === 0 && (
          <Button variant="outlined" onClick={onClose}>
            Done
          </Button>
        )}
        {flowVolumeCalibrationAttempt > 0 && flowVolumeCalibrationAttempt < 4 && (
          <Button variant="outlined" onClick={() => goToStep('BeforeBegin')}>
            Try Again
          </Button>
        )}
        {flowVolumeCalibrationAttempt > 3 && (
          <Button variant="outlined" onClick={onClose}>
            Skip
          </Button>
        )}
      </Box>
    </StyledRootBox>
  );
};

const StyledRootBox = styled('Box')(({ theme: { palette, spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: palette.background.paper,
  height: '100%',

  '.content': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
  },

  '.section': {
    '&.disabled': {
      '*': {
        opacity: 0.65,
      },
    },
    '.section_header': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '.sensor_recalibrate': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      columnGap: spacing(1),
    },
    '.sensor_title': {
      display: 'flex',
      alignItems: 'center',
    },
    '&_content': {
      border: `1px solid ${palette.outline.main}`,
      borderRadius: '8px',
      backgroundColor: palette.background.overlay2,
      padding: '24px',
      boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '8px',
    },
    '.graphs': {
      margin: `0 ${spacing(4)}`,
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      rowGap: spacing(3),
    },
    '.info_title': {
      display: 'flex',
      flexDirection: 'column',
      '&.noise': {
        marginTop: '8px',
      },
    },
    '&.flow_volume': {
      marginTop: '24px',
    },
  },
  '.outOfRange': {
    display: 'flex',
    alignItems: 'center',
    '.out_of_range_text': {
      marginLeft: '8px',
    },
  },

  '.sensor_heading': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '.sensor_title': {
    display: 'flex',
    alignItems: 'center',
    columnGap: spacing(2),
  },
  '.calibration_info': {
    display: 'flex',
    columnGap: spacing(2),
    alignItems: 'center',
    padding: `${spacing(2)} 0`,

    '& svg': {
      fill: palette.text.secondary,
    },
  },
  '.action_box': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

    button: {
      textTransform: 'none',

      width: '100%',
      height: '40px',

      '&.btn': {
        backgroundColor: '#262626',
        color: palette.whiteEmphasis.high,
      },
    },
  },

  '.hidden': {
    display: 'none !important',
  },
}));

export default observer(Initial);
