import React, { useState } from 'react';
import clsx from 'clsx';

import { Box, Typography, Button, styled } from '@mui/material';

import { dateUtilities } from 'utils';

import CalibrateDrawer from 'components/@SystemConfiguration/HardwareManagement/Mobile/CalibrateDrawer';

const calibrationStatusMap = {
  0: 'Not Calibrated',
  1: 'Calibrated with Issues',
  2: 'Calibrated',
  3: 'Calibrated - Legacy',
};

const SensorCalibrationTab = ({ sensor }) => {
  const isPreviousCalibrated = [1, 2].includes(sensor.calibration_status_code);

  const [openCalibrationDrawer, setOpenCalibrationDrawer] = useState(false);

  return (
    <StyledRootBox>
      <Box className="item">
        <Box className="item_row">
          <Typography className="info_point_title">Calibration Status</Typography>
          <Typography className="info_point_value">
            {calibrationStatusMap[sensor.calibration_status_code] || 'N/A'}
          </Typography>
        </Box>
      </Box>
      <Box className="item">
        <Typography className="info_point_title">Date Calibrated</Typography>
        <Typography className="info_point_value">
          {isPreviousCalibrated
            ? dateUtilities.formatDateIncludingToday(sensor.latest_calibration_at, {
                fallback: 'N/A',
              })
            : 'N/A'}
        </Typography>
      </Box>
      <Box className="item">
        <Typography className="info_point_title">Calibrated by</Typography>
        <Typography className="info_point_value">
          {sensor._users_display_name_latest_calibration_by || 'N/A'}
        </Typography>
      </Box>
      <Box className={clsx('item', { hidden: !isPreviousCalibrated })}>
        <Button
          className="calibrate_button"
          variant="outlined"
          onClick={() => setOpenCalibrationDrawer(true)}
        >
          Calibration Details
        </Button>
      </Box>

      {openCalibrationDrawer && (
        <CalibrateDrawer
          sensor={sensor}
          open={openCalibrationDrawer}
          onClose={() => setOpenCalibrationDrawer(false)}
          readOnly
        />
      )}
    </StyledRootBox>
  );
};

export default SensorCalibrationTab;

const StyledRootBox = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',

  '.item': {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0',
    borderBottom: `1px solid ${palette.secondary.main}`,
    fontWeight: 500,

    '&.hidden': {
      display: 'none',
    },

    '.info_point_title': {
      fontSize: '11px',
      color: palette.whiteEmphasis.medium,
    },

    '.info_point_value': {
      fontSize: '14px',
      color: palette.whiteEmphasis.high,
    },

    '&.row': {
      flexDirection: 'row',
    },
  },

  '.item_row': {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '50%',
  },

  '.calibrate_button': {
    marginTop: '10px',
  },
}));
