import { flow, types } from 'mobx-state-tree';

import { getRootStore } from 'models/root';
import { inventoryKegRate } from 'models/types';
import api from 'services/API';
import { dateUtilities } from 'utils';

export const inventoryKegRateInitialState = {
  all: [],
  isLoaded: true,
};

export const inventoryKegRateModel = types
  .model({
    all: types.array(inventoryKegRate),
    isLoaded: types.boolean,
  })
  .views(self => ({
    get loading() {
      return !self.isLoaded;
    },

    getItemsByFilters({ cooler_ids, beverage_id, container_id }) {
      return self.all.find(
        item =>
          item.beverage_id === beverage_id &&
          item.container_id === container_id &&
          cooler_ids.includes(item.cooler_id),
      );
    },

    getKegRatePerDayByFilters({ cooler_ids, beverage_id, container_id }) {
      const items = self.getItemsByFilters({ cooler_ids, beverage_id, container_id });
      if (items) {
        return items.per_calendar_day;
      }

      return null;
    },
  }))
  .actions(self => {
    return {
      fetch: flow(function* () {
        try {
          const root = getRootStore();
          if (root.establishmentStore.establishment.id) {
            const { from, to } = dateUtilities.getDatesByPeriod('inventory_consumption_days');

            const body = {
              establishment_id: root.establishmentStore.establishment.id,
              from_ts: from,
              to_ts: to,
              cooler_ids: root.coolersStore.allCoolerIds,
            };
            self.isLoaded = false;

            const response = yield api.getInventoryKegRate(body);
            self.all = response.data.result;
            self.isLoaded = true;
          }
        } catch (err) {
          console.error(err);
          return Promise.reject(err);
        }
      }),
    };
  });
