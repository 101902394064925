import React from 'react';
import { observer } from 'mobx-react';

import { Box, Button, Typography, styled } from '@mui/material';

import { WarningAmber as WarningAmberIcon } from '@mui/icons-material';

import { BRULineSchema } from 'assets/icons';

const NotDetected = ({ onTryAgain }) => {
  return (
    <StyledRootBox>
      <Box className="subtitle_box">
        <WarningAmberIcon className="warning_icon" />
        <Typography className="warning_title">Potential Draft Line Issue</Typography>
        <Typography className="subtitle">
          Beer may still flow from the tap, but the back-end sensor did not detect it. This could
          happen if the draft line near the sensor is empty.
        </Typography>
      </Box>
      <Box className="main_box">
        <Box className="title_wrapper">
          <Typography className="title">To remedy:</Typography>
          <Typography className="subtitle">
            Check to make sure the unit has beer present both above and below the sensor and try on
            more time.
          </Typography>
        </Box>
        <Box className="bru_line_schema_wrapper">
          <BRULineSchema className="bru_line_schema_icon" />
        </Box>
      </Box>

      <Box className="action_box">
        <Button variant={'contained'} onClick={onTryAgain} color="primary">
          Try Again
        </Button>
      </Box>
    </StyledRootBox>
  );
};

export default observer(NotDetected);

const StyledRootBox = styled(Box)(({ theme: { palette } }) => ({
  width: '100%',
  // height: '504px',

  '.dialog_content': {
    padding: '0 !important',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },

  '.title': {
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'center',
    color: palette.whiteEmphasis.medium,
    marginTop: '16px',
  },

  '.subtitle': {
    fontSize: '12px',
    color: palette.whiteEmphasis.high,
    marginTop: '12px',
    textAlign: 'center',
  },

  '.warning_icon': {
    width: '40px',
    height: '40px',
    color: palette.status.red,
  },

  '.warning_title': {
    fontSize: '14px',
    fontWeight: '500',
    color: palette.status.red,
  },

  '.main_box': {
    width: '100%',
    height: '317px',
    padding: '0 24px',
    margin: '24px 0',
    border: `1px solid #313131`,
    backgroundColor: '#252525',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '.title_wrapper': {
      marginBottom: '48px',

      '.title': {
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'left',

        color: palette.whiteEmphasis.high,
      },
      '.subtitle': {
        fontSize: '12px',
        color: palette.whiteEmphasis.high,
        marginTop: '12px',
        textAlign: 'left',
      },
    },
  },

  '.subtitle_box': {
    margin: '24px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '.bru_line_schema_wrapper': {
    width: '112px',
    height: '95px',
  },

  '.pull_tap_icon': {
    width: '88px',
    height: '88px',
  },

  '.warning_box': {
    position: 'absolute',
    transform: 'translate(34px, -50px)',
    width: '24px',
    height: '24px',

    '&.hidden': {
      visibility: 'hidden',
    },

    '& > svg': {
      color: palette.primary.main,
    },
  },

  '.red': {
    color: palette.status.red,
  },

  '.action_box': {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    left: 0,
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'center',
    borderTop: `1px solid #313131`,

    button: {
      textTransform: 'none',
      width: 'calc(100% - 40px)',
      height: '40px',
    },
  },

  '.error': {
    color: palette.status.red,
    width: '100%',
    transform: 'translate(-110px, 5px)',

    '&.hide': {
      display: 'none',
    },
  },
}));
