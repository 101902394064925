import { types } from 'mobx-state-tree';

import { onTapTileLayout } from 'config/establishment';

const DigitalMenu = types.model({
  kegLevel: types.maybeNull(types.boolean),
  producer: types.maybeNull(types.boolean),
  flipVertical: types.maybeNull(types.boolean),
  categories: types.maybeNull(types.union(types.boolean, types.array(types.string))),
  category: types.maybeNull(types.string),
  primaryColor: types.maybeNull(types.string),
  orientation: types.maybeNull(types.string),
  backgroundImage: types.maybeNull(types.string),
  font: types.maybeNull(types.string),
  tilesPerPage: types.maybeNull(types.union(types.number, types.string)),
  tilesColumns: types.maybeNull(types.union(types.number, types.string)),
  establishmentInfoPosition: types.maybeNull(types.string),
  pageDurationInSeconds: types.maybeNull(types.union(types.number, types.string)),
});

const MetadataModel = types.model({
  category: types.array(types.string),
  cleaning_method: types.maybeNull(types.string),
  target_flow_rate: types.maybeNull(types.number),
  target_line_temp: types.maybeNull(types.number),
  target_cooler_temp: types.maybeNull(types.number),
  cleaning_rinse_method: types.maybeNull(types.string),
  target_flow_rate_unit: types.maybeNull(types.string),
  default_keg_expiration: types.maybeNull(types.number),
  cleaning_target_duration: types.maybeNull(types.number),
  draft_system_cleaning_interval: types.maybeNull(types.number),
  unit_preference: types.maybeNull(types.union(types.number, types.string)),
  bus_practice_ars_multiple: types.maybeNull(types.boolean),
  ontap_tile_layout: types.optional(types.integer, onTapTileLayout.Default),
});

const PhysicalAddress = types.model({
  city: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  region: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  zipcode: types.maybeNull(types.union(types.string, types.number)),
  locality: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
});

const TimezoneOffset = types.model({
  hours: types.maybeNull(types.number),
});

export const Establishment = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  uuid: types.maybeNull(types.string),
  physical_address: types.maybeNull(PhysicalAddress),
  phone_number: types.maybeNull(types.string),
  archived: types.maybeNull(types.boolean),
  metadata: MetadataModel,
  image_url: types.maybeNull(types.string),
  type_code: types.maybeNull(types.number),
  draft_system_cleaning_interval_hours: types.maybeNull(types.number),
  default_keg_expiration_days: types.maybeNull(types.number),
  hours_of_operation: types.maybeNull(types.string),
  integration_id: types.maybeNull(types.integer),
  day_week_begins: types.maybeNull(types.integer),
  default_pour_cost_pct: types.maybeNull(types.number),
  display_name: types.maybeNull(types.string),
  pos_hour_integration_done: types.maybeNull(types.integer),
  pos_modifiers: types.maybeNull(types.boolean),
  time_zone: types.optional(types.string, 'UTC', [null, undefined]),
  menu_active: types.maybeNull(types.boolean),
  menu_metadata: types.maybeNull(DigitalMenu),
  producer_id: types.maybeNull(types.number),
  default_head_space_pct: types.maybeNull(types.number),
  _pg_timezone_names_utc_offset: types.optional(TimezoneOffset, { hours: 0 }, [null, undefined]),
  _producers_name: types.maybeNull(types.string),
});
