import React, { useState, useCallback, useMemo, useContext } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Box, IconButton, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { ArrowBackIos, Close } from '@mui/icons-material';
import clsx from 'clsx';

import { useStore } from 'hooks';

import { CalibrationContext, useCalibration } from 'context/calibration';
import Initial from './Initial';
import BeforeBegin from './BeforeBegin';
import PourDetection from './PourDetection';

const CalibrateDrawer = ({ sensor, open, onClose, readOnly = false }) => {
  const { topologyManagementStore } = useStore();
  const { currentStep, setCurrentStep } = useContext(CalibrationContext);
  const [selectedSensor, setSelectedSensor] = useState(sensor);

  const [isError, setIsError] = useState(false);

  const handleClose = useCallback(() => {
    topologyManagementStore.resetRecentPours();
    onClose();
  }, [onClose, topologyManagementStore]);

  const handleBack = useCallback(() => {
    if (currentStep === 'Initial') handleClose();
    if (currentStep === 'BeforeBegin') setCurrentStep('Initial');
    if (currentStep === 'Detecting pour') {
      if (isError) setIsError(false);
      else if (topologyManagementStore.pours.length) topologyManagementStore.resetRecentPours();
      else setCurrentStep('BeforeBegin');
    }
  }, [currentStep, isError, handleClose, topologyManagementStore, setCurrentStep]);

  const MainContent = useMemo(() => {
    if (currentStep === 'Initial')
      return (
        <Initial
          sensor={selectedSensor}
          onClose={handleClose}
          goToStep={setCurrentStep}
          resetState={topologyManagementStore.resetRecentPours}
          readOnly={readOnly}
        />
      );
    if (currentStep === 'BeforeBegin')
      return (
        <BeforeBegin
          onBack={handleClose}
          goToStep={setCurrentStep}
          resetState={topologyManagementStore.resetRecentPours}
        />
      );
    if (currentStep === 'Detecting pour')
      return (
        <PourDetection
          goToStep={setCurrentStep}
          sensor={selectedSensor}
          handleClose={handleClose}
          setSelectedSensor={setSelectedSensor}
          isError={isError}
          setIsError={setIsError}
        />
      );
    return '';
  }, [
    currentStep,
    selectedSensor,
    handleClose,
    topologyManagementStore.resetRecentPours,
    isError,
    setCurrentStep,
    readOnly,
  ]);

  return (
    <StyledRootDrawer open={open} onClose={handleClose} anchor="right">
      <Box className="header">
        {currentStep !== 'Initial' && (
          <IconButton onClick={handleBack} className="back_button">
            <ArrowBackIos />
          </IconButton>
        )}
        <Box className="title_box">
          <Typography className={clsx({ error: isError })} variant="h7">
            Calibrate {isError ? 'Error' : currentStep !== 'Initial' ? 'Flow' : 'Sensor'}
          </Typography>
        </Box>
        <IconButton onClick={handleClose} className="close_button">
          <Close />
        </IconButton>
      </Box>

      <Box className="body">{MainContent}</Box>
    </StyledRootDrawer>
  );
};

const CalibrateDrawerWrapper = props => (
  <CalibrationContext.Provider value={useCalibration(props.sensor)}>
    <CalibrateDrawer {...props} />
  </CalibrationContext.Provider>
);

export default observer(CalibrateDrawerWrapper);

const StyledRootDrawer = styled(Drawer)(({ theme: { palette } }) => ({
  '& .MuiDrawer-paper': {
    width: '100%',
    backgroundColor: palette.background.paper,
  },

  '.header': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    borderBottom: `1px solid #313131`,
    padding: '16px',

    '.back_button': {
      position: 'absolute',
      left: '16px',
    },

    '.title_box': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '.subtitle': {
      fontSize: '12px',
      color: palette.whiteEmphasis.medium,
    },

    '.close_button': {
      position: 'absolute',
      right: '16px',
      minWidth: '50px',
      fontSize: '12px',
      // color: palette.primary.main,
    },
  },

  '.body': {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',

    '.info_box': {
      marginBottom: '20px',
      display: 'flex',
      flexDirection: 'column',
    },
  },

  // Styles for all inputs in page
  '.MuiInputBase-root': {
    backgroundColor: palette.secondary.dark,
    color: palette.whiteEmphasis.high,
  },
  '&.MuiTextField-root': {
    marginBottom: 0,
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: palette.outline.main,
  },

  '& .MuiIconButton-edgeEnd': {
    marginRight: -3,
    color: alpha(palette.white, 0.5),
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },

  '.error': {
    color: palette.status.red,
  },
}));
