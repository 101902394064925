export const skuFilters = {
  unmatched: 'unmatched',
  matched: 'matched',
  ignored: 'ignored',
  all: 'all',
};

export const sortByAgeOptions = {
  matched: 'matched_at',
  unmatched: 'earliest_sale_at',
  ignored: 'ignored_at',
};
