import { types, flow } from 'mobx-state-tree';
import { isAfter } from 'date-fns';
import { isBefore } from 'date-fns';
import { format } from 'date-fns';
import { isSameDay } from 'date-fns';

import api from 'services/API';
import { dateUtilities } from 'utils';
import { getRootStore } from 'models/root';
import { Cleaning } from 'models/types';

export const cleaningsInitialState = {
  all: [],
  filters: null,
  page: 1,
  limit: 20,
  order: 'desc',
  isLoaded: false,
};

export const cleaningsModel = types
  .model({
    all: types.array(Cleaning),
    filters: types.maybeNull(
      types.model({
        lineIds: types.maybeNull(types.array(types.integer)),
        fromDate: types.maybeNull(types.Date),
        toDate: types.maybeNull(types.Date),
      }),
    ),
    page: types.integer,
    limit: types.integer,
    order: types.enumeration('order', ['desc', 'asc']),
    isLoaded: types.boolean,
  })
  .views(self => ({
    get areFiltersApplied() {
      const root = getRootStore();
      return (
        (self.filters &&
          self.filters.lineIds &&
          Array.isArray(self.filters.lineIds) &&
          self.filters.lineIds.length !== root.linesStore.linesIDs.length) ||
        (self.filters && self.filters.fromDate) ||
        (self.filters && self.filters.toDate)
      );
    },

    get filteredCleanings() {
      let filtered = self.all;

      if (self.filters && self.filters.lineIds && Array.isArray(self.filters.lineIds)) {
        if (self.filters.lineIds.length > 0) {
          filtered = filtered.filter(cleaning => self.filters.lineIds.includes(cleaning.line_id));
          if (self.filters.fromDate) {
            const fromDate = new Date(format(self.filters.fromDate, 'yyyy-MM-dd'));
            filtered = filtered.filter(cleaning => {
              const formattedDate = format(new Date(cleaning.cleaned_from), 'yyyy-MM-dd');
              const dateFromFormatted = new Date(formattedDate);
              return isSameDay(dateFromFormatted, fromDate) || isAfter(dateFromFormatted, fromDate);
            });
          }
          if (self.filters.toDate) {
            const toDate = new Date(format(self.filters.toDate, 'yyyy-MM-dd'));
            filtered = filtered.filter(cleaning => {
              const formattedDate = format(new Date(cleaning.cleaned_to), 'yyyy-MM-dd');
              const dateFromFormatted = new Date(formattedDate);
              return isSameDay(dateFromFormatted, toDate) || isBefore(dateFromFormatted, toDate);
            });
          }
        } else {
          filtered = [];
        }
      }
      return filtered;
    },

    get groupedLines() {
      const groupedLines = {
        byStartedDate: {},
        byLineId: {},
      };

      const sorted = self.filteredCleanings.slice().sort((a, b) => {
        if (a.cleaned_from && b.cleaned_from) {
          if (new Date(a.cleaned_from) > new Date(b.cleaned_from)) {
            return 1;
          } else {
            return -1;
          }
        }
        return 0;
      });

      sorted
        .filter(cleaning => cleaning.cleaned_to)
        .forEach(cleaning => {
          const formattedDate = dateUtilities.makeDateFormatMDYYYY(
            dateUtilities.convertUtcToZonedTime(cleaning.cleaned_from),
          );

          if (!groupedLines.byStartedDate[formattedDate]) {
            groupedLines.byStartedDate[formattedDate] = {
              originalTimestamp: null,
              items: [],
            };
          }

          if (!groupedLines.byLineId[cleaning.line_id]) {
            groupedLines.byLineId[cleaning.line_id] = {
              originalTimestamp: null,
              items: [],
            };
          }

          groupedLines.byStartedDate[formattedDate].originalTimestamp =
            dateUtilities.convertUtcToZonedTime(cleaning.cleaned_from);
          groupedLines.byStartedDate[formattedDate].items.push(cleaning);

          groupedLines.byLineId[cleaning.line_id].originalTimestamp =
            dateUtilities.convertUtcToZonedTime(cleaning.cleaned_from);
          groupedLines.byLineId[cleaning.line_id].items.push(cleaning);
        });

      return groupedLines;
    },

    get groupedAndMappedLines() {
      const lines = Object.keys(self.groupedLines.byStartedDate).map(key => {
        return {
          key: key,
          originalTimestamp: self.groupedLines.byStartedDate[key].originalTimestamp,
          items: self.groupedLines.byStartedDate[key].items,
        };
      });

      return self.order === 'desc' ? lines.reverse() : lines;
    },

    get paginatedLines() {
      return self.groupedAndMappedLines.slice(0, self.page * self.limit);
    },

    get hasMore() {
      return self.groupedAndMappedLines.length > self.page * self.limit;
    },
  }))
  .actions(self => {
    return {
      fetchCleanings: flow(function* () {
        try {
          const response = yield api.getCleanings();
          if (response && response.data && response.data.result) {
            self.all.replace(response.data.result);
          }
          self.isLoaded = true;
        } catch (err) {
          self.isLoaded = false;
          return Promise.reject(err);
        }
      }),

      setFilters(filters) {
        self.filters = filters;
      },

      updateOrInsert(cleaning) {
        if (cleaning && cleaning.id) {
          const eventIndex = self.all.findIndex(event => event && event.id === cleaning.id);
          const eventExist = eventIndex >= 0;
          if (eventExist) {
            self.all[eventIndex] = cleaning;
          } else {
            const updatedEvents = [...self.all, cleaning];
            self.all.replace(updatedEvents);
          }
        }
      },

      addCleaning: flow(function* (data) {
        try {
          const response = yield api.cleanAdd(data);
          if (response?.data?.result?.added?.length && response?.data?.result?.lines?.length) {
            const root = getRootStore();
            response.data.result.added.forEach(self.updateOrInsert);
            response.data.result.lines.forEach(root.linesStore.updateLine);
          }
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      updateCleaning: flow(function* (data) {
        try {
          const response = yield api.cleanModify(data);
          if (response?.data?.result?.modified?.length && response?.data?.result?.lines?.length) {
            const root = getRootStore();
            response.data.result.modified.forEach(self.updateOrInsert);
            response.data.result.lines.forEach(root.linesStore.updateLine);
          }
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      stopCleaning: flow(function* (line_id) {
        try {
          const stopResponse = yield api.cleanStop({ line_ids: [line_id] });
          if (stopResponse && stopResponse.data && stopResponse.data.result) {
            const { lines, stopped } = stopResponse.data.result;
            const root = getRootStore();
            stopped && Array.isArray(stopped) && stopped.forEach(self.updateOrInsert);
            lines &&
              Array.isArray(lines) &&
              lines.forEach(line => {
                root.linesStore.updateLine({
                  ...line,
                  _cleanings_latest_cleaned_to: new Date().toISOString(),
                });
              });

            return stopResponse;
          }
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      startCleaning: flow(function* (line_id) {
        try {
          const root = getRootStore();
          const line_ids = Array.isArray(line_id) ? line_id : [line_id];
          const startResponse = yield api.cleanStart({
            line_ids,
            next_clean_in_hours:
              root.establishmentStore.establishment.draft_system_cleaning_interval_hours,
          });

          if (startResponse && startResponse.data && startResponse.data.result) {
            const root = getRootStore();
            const { lines, started } = startResponse.data.result;
            started && Array.isArray(started) && started.forEach(self.updateOrInsert);
            lines &&
              Array.isArray(lines) &&
              lines.forEach(line => {
                root.linesStore.updateLine({
                  ...line,
                  _cleanings_cleaned_from_current: new Date().toISOString(),
                });
              });

            return startResponse;
          }
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      startCleaningAll: flow(function* () {
        const root = getRootStore();
        const lineIds = root.linesStore.lines
          .filter(el => !el.current_cleaning_id)
          .map(line => line.id);
        try {
          const startResponse = yield api.cleanStart({
            line_ids: lineIds,
            next_clean_in_hours:
              root.establishmentStore.establishment.draft_system_cleaning_interval_hours,
          });
          if (startResponse && startResponse.data && startResponse.data.result) {
            const { lines, started } = startResponse.data.result;
            const root = getRootStore();
            started && Array.isArray(started) && started.forEach(self.updateOrInsert);
            lines &&
              Array.isArray(lines) &&
              lines.forEach(line => {
                root.linesStore.updateLine({
                  ...line,
                  _cleanings_cleaned_from_current: new Date().toISOString(),
                });
              });

            return startResponse;
          }
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      stopCleaningAll: flow(function* () {
        const root = getRootStore();
        const lineIds = root.linesStore.lines
          .filter(el => el.current_cleaning_id)
          .map(line => line.id);
        try {
          const stopResponse = yield api.cleanStop({
            line_ids: lineIds,
          });
          if (stopResponse && stopResponse.data && stopResponse.data.result) {
            const { lines, started } = stopResponse.data.result;
            const root = getRootStore();
            started && Array.isArray(started) && started.forEach(self.updateOrInsert);
            lines &&
              Array.isArray(lines) &&
              lines.forEach(line => {
                root.linesStore.updateLine({
                  ...line,
                  _cleanings_cleaned_from_current: new Date().toISOString(),
                });
              });
            return stopResponse;
          }
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      removeCleaning: flow(function* (lineIds) {
        try {
          const response = yield api.cleanRemove(lineIds);
          if (response?.data?.result?.removed?.length && response?.data?.result?.lines?.length) {
            const root = getRootStore();
            const removedIds = response.data.result.removed.map(({ id }) => id);
            const updatedCleanings = self.all.filter(({ id }) => !removedIds.includes(id));
            response.data.result.lines.forEach(root.linesStore.updateLine);
            self.all.replace(updatedCleanings);
          }
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      scheduleCleaning: flow(function* (data) {
        try {
          const response = yield api.cleanSchedule(data);
          const root = getRootStore();
          response.data.result.lines.forEach(root.linesStore.updateLine);
          return response;
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      setPage(value) {
        self.page = value;
      },

      setOrder(value) {
        self.order = value;
      },

      handleCleanStart({ started } = []) {
        started && Array.isArray(started) && started.forEach(self.updateOrInsert);
      },

      handleCleanStop({ stopped } = []) {
        stopped && Array.isArray(stopped) && stopped.forEach(self.updateOrInsert);
      },

      setCleanings(cleanings) {
        self.all.replace(cleanings);
        self.isLoaded = true;
      },
    };
  });
