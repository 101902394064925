import { useState } from 'react';

const useFiltersContextValues = () => {
  const [open, setOpen] = useState(false);

  return {
    open: open,
    setOpen,
  };
};

export default useFiltersContextValues;
